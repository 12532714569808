import React, { useState } from "react";
import api from "../../lib/api";
import { toast } from "sonner";
import { FiTrash } from "react-icons/fi";

function DiscountCode({ order, setOrder }) {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(false);

  const applyDiscount = async () => {
    try {
      const { data } = await api.put(`/checkout/${order.id}/discount`, {
        code,
      });
      setOrder(data.order);
      toast.success("Código de descuento aplicado");
    } catch (error) {
      setError(true);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className='mt-8'>
      <label htmlFor='discount' className='text-gray-500'>
        ¿Tienes un código de descuento?
      </label>
      <div className='flex gap-4 items-center'>
        {!order.discountCode ? (
          <OnNoCode
            setCode={setCode}
            setError={setError}
            code={code}
            applyDiscount={applyDiscount}
            order={order}
            error={error}
            discountCode={order.discountCode}
          />
        ) : (
          <OnCode
            discountCode={order.discountCode}
            orderId={order.id}
            setOrder={setOrder}
          />
        )}
      </div>
    </div>
  );
}

function OnNoCode({
  setCode,
  setError,
  code,
  applyDiscount,
  order,
  error,
  discountCode,
}) {
  return (
    <div className='w-full'>
      <input
        defaultValue={discountCode}
        type='text'
        id='discount'
        className={`${
          error ? "text-danger" : "text-gray-400"
        } bg-white border-gray-300 p-2 focus:outline-none mt-2 w-3/4`}
        placeholder='Escribe tu código de descuento'
        onInput={(e) => {
          setCode(e.target.value.toUpperCase());
          setError(false);
        }}
      />
      {code && (
        <button
          onClick={() => applyDiscount()}
          className='bg-blue-600 w-1/4 text-white p-2  mt-2'
        >
          Aplicar
        </button>
      )}
    </div>
  );
}
function OnCode({ discountCode, orderId, setOrder }) {
  const removeDiscount = async () => {
    try {
      const { data } = await api.delete(`/checkout/${orderId}/discount`);
      setOrder(data.order);
      toast.success("Código de descuento eliminado");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className='flex items-center gap-4 mt-2'>
      <button
        onClick={() => removeDiscount()}
        className='text-gray-600 hover:text-danger p-2 rounded-full cursor-pointer'
      >
        <FiTrash />
      </button>
      <div>
        <p className='text-gray-500'>
          El código de descuento <strong>{discountCode}</strong> ha sido
          aplicado
        </p>
      </div>
    </div>
  );
}

export default DiscountCode;
