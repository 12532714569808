import React from "react";
import ProductCard from "./ProductCard";
import useProducts from "../../hooks/useProducts";

function ProducCarousel() {
  const products = useProducts({ limit: 5 });

  return (
    <div className="flex flex-wrap items-center gap-8 md:flex-row md:gap-0 justify-between w-3/4 mt-8 md:max-h-[400px] overflow-y-hidden">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}

export default ProducCarousel;
