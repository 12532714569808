import { useContext, useState } from "react";
import api from "../lib/api";
import { UserContext } from "../context/User";
import { toast } from "sonner";

const useLogin = () => {
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await api.post("/user/login", login);
      setUser(res.data);
      window.location.href = "/";
      setLoading(false);
    } catch (error) {
      toast.error("Credenciales incorrectas");
      console.log(error);
      setLoading(false);
    }
  };

  return {
    handleChange,
    handleSubmit,
    login,
    loading,
  };
};

export default useLogin;
