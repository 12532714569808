import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import Nav from "./components/nav/Nav";
import Login from "./views/Login";
import { Toaster } from "sonner";
import Cart from "./views/Cart";
import Checkout from "./views/Checkout";
import Profile from "./views/Profile";
import Keys from "./views/Keys";
import Orders from "./views/Orders";
import Order from "./views/Order";
import Products from "./views/Products";
import Chat from "./views/Chat";
import Docs from "./views/Docs";
import Footer from "./components/footer/Footer";
import RecoveryMail from "./views/RecoveryMail";
import RecoveryPassword from "./views/RecoveryPassword";
import Register from "./views/Register";

function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart/resume" element={<Cart />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/cuenta" element={<Profile />} />
        <Route path="/claves" element={<Keys />} />
        <Route path="/ordenes" element={<Orders />} />
        <Route path="/orden/:id" element={<Order />} />
        <Route path="/productos" element={<Products />} />
        <Route path="/recovery" element={<RecoveryMail />} />
        <Route path="/recovery/:code" element={<RecoveryPassword />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
