import { useEffect, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";

const useRecoveryPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { code } = useParams();
  const [passwordData, setPasswordData] = useState({
    password: null,
    passwordConfirm: null,
  });

  useEffect(() => {
    setPasswordData((prev) => ({ ...prev, code }));
  }, [code]);

  const handleChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!passwordData.password) {
      toast.error("Ingresa una contraseña");
      return;
    }

    if (passwordData.password.length < 8) {
      toast.error("La contraseña debe tener al menos 8 caracteres");
      return;
    }

    if (passwordData.password !== passwordData.passwordConfirm) {
      toast.error("Las contraseñas no coinciden");
      return;
    }
    try {
      setLoading(true);
      await api.put("/user/recovery", passwordData);
      navigate("/login");
      toast.success("Contraseña actualizada");
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  return {
    handleChange,
    handleSubmit,
    passwordData,
    loading,
  };
};

export default useRecoveryPassword;
