import React from "react";
import AccountNav from "../components/Account/AccountNav";
import useMyKeys from "../hooks/useMyKeys";
import KeysContainer from "../components/keys/KeysContainer";

function Keys() {
  const { keys } = useMyKeys();
  return (
    <main className="w-3/4 m-auto my-8 flex flex-col md:flex-row gap-8 md:gap-40">
      <AccountNav />

      <section className="md:w-3/4">
        <header>
          <h4 className="text-3xl font-bold">Mis Claves</h4>
          <p className="text-gray-400 text-xs">
            Las claves que has comprado apareceran aqui. Presiona el boton de
            "Ver Clave" para revelar la clave.
          </p>
        </header>

       {<KeysContainer keys={keys} />}
      </section>
    </main>
  );
}

export default Keys;
