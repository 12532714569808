const ROLES = {
  USER: 0,
  DEALER: 1,
  ADMIN: 2,
};

const KEY_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1,
  DEMAGED: 2,
};

const PAYMENT_METHOD = {
  MANUAL: 0,
  PAYPAL: 1,
  STRIPE: 2,
};

const ORDER_STATUS_CONST = {
  CREATED: 0,
  WAITING_PAYMENT: 1,
  WAITING_PAYMENT_APROVE: 2,
  WAITING_PAYMENT_TRANSACTION: 3,
  WAITING_STOCK: 4,
  DELIVERIED: 5,
  WAITING_DEALER_PAYMENT: 6,
  PAYED: 7,
  WAITING_PAYMENT_AND_STOCK: 8,
  WAITING_PAYMENT_APROVE_AND_STOCK: 9,
};

const ORDER_STATUS = [
  { id: 0, name: "Creado", color: "success" },
  { id: 1, name: "Esperando pago", color: "success" },
  { id: 2, name: "Esperando aprobación", color: "warning" },
  { id: 3, name: "Esperando transacción", color: "success" },
  { id: 4, name: "Esperando stock", color: "danger" },
  { id: 5, name: "Entregado", color: "success" },
  { id: 6, name: "Pendiente de pago", color: "warning" },
  { id: 7, name: "Pagado", color: "success" },
  { id: 8, name: "Esperando pago", color: "success" },
  { id: 9, name: "Esperando aprobación", color: "warning" },
];
export { ROLES, KEY_STATUS, PAYMENT_METHOD, ORDER_STATUS, ORDER_STATUS_CONST };
