import React from "react";
import { AcmeLogo } from "../components/AcmeLogo";
import useRecoveryPassword from "../hooks/useRecoveryPassword";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

function RecoveryPassword() {
  const { handleChange, handleSubmit, loading } = useRecoveryPassword();
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    passwordConfirm: false,
  });
  return (
    <>
      <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto flex justify-center sm:w-full sm:max-w-sm">
          <AcmeLogo />
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" action="#" method="POST">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 ">
                Nueva contraseña
              </label>
              <div class="mt-2 flex gap-2">
                <input
                  onChange={(e) => handleChange(e)}
                  id="password"
                  name="password"
                  type={showPassword.password ? "text" : "password"}
                  autocomplete="password"
                  required
                  placeholder="Contraseña"
                  class="block w-full rounded-md border-0 py-1.5 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                />
                <button
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      password: !showPassword.password,
                    })
                  }
                  type="button"
                >
                  {showPassword.password ? <FaRegEyeSlash /> : <FaRegEye />}
                </button>
              </div>

              <div class="mt-4 flex gap-2">
                <input
                  onChange={(e) => handleChange(e)}
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type={showPassword.passwordConfirm ? "text" : "password"}
                  autocomplete="password"
                  required
                  placeholder="Confirmar Contraseña"
                  class="block w-full rounded-md border-0 py-1.5 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                />
                <button
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      passwordConfirm: !showPassword.passwordConfirm,
                    })
                  }
                  type="button"
                >
                  {showPassword.passwordConfirm ? (
                    <FaRegEyeSlash />
                  ) : (
                    <FaRegEye />
                  )}
                </button>
              </div>
            </div>

            <div></div>

            <div>
              <button
                onClick={(e) => handleSubmit(e)}
                type="button"
                class="flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                <span className={loading && "loading loading-spinner"}></span>
                {loading ? "Cargando" : "Cambiar contraseña"}
              </button>
            </div>
          </form>

          <p class="mt-10 text-center text-sm text-gray-500">
            ¿Problemas?{" "}
            <a
              href="mailto:support@biocdkeys.com"
              class="font-semibold leading-6 text-secondary"
            >
              support@biocdkeys.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default RecoveryPassword;
