import React, { useContext } from "react";
import { UserContext } from "../context/User";
import { User } from "@nextui-org/react";
import AccountNav from "../components/Account/AccountNav";
import formatTime from "../lib/formatTime";

function Profile() {
  const { user } = useContext(UserContext);

  if (!user) return null;
  return (
    <main className="w-3/4 m-auto mt-8 flex flex-col md:flex-row gap-8 md:gap-40 min-h-screen">
      <AccountNav />

      <section className="">
        <header>
          <div>
            <User
              name={user.name + " " + user.lastName}
              description={user.email}
            />

            {user.banned ? (
              <div className="bg-red-500 text-white rounded-xl p-1 text-center text-xs">
                Usuario Baneado
              </div>
            ) : null}
          </div>

          <ul className="mt-2">
            <li className="text-sm">
                <strong>Miebro desde:</strong> {formatTime(user.createdAt)}
            </li>
          </ul>
        </header>
      </section>
    </main>
  );
}

export default Profile;
