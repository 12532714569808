import React, { useContext } from "react";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { CartContext } from "../../context/Cart";

function ProductCard({ product }) {

  const {addToCart} = useContext(CartContext);
  return (
    <div className="bg-[#181818] px-10 py-6 rounded-lg md:max-w-[250px] min-h-[400px]">
      <img alt={product.name} src={process.env.REACT_APP_CDN_URL + "/" + product.imgId} className="  md:max-h-[250px]  rounded-xl" />
      <div className="mt-5">
        <h6 className="font-bold text-sm">{product.name}</h6>
        <div className="flex gap-4 items-center mt-2">

          <button onClick={() => addToCart(product.id)} className="rounded-full p-2 border-white border hover:border-x-secondary-500 hover:text-secondary ">
            <MdOutlineAddShoppingCart />
          </button>
          <span>{product.price} USD</span>

        </div>
      </div>
    </div>
  );
}

export default ProductCard;
