import React from "react";
import AccountNav from "../components/Account/AccountNav";
import useOrder from "../hooks/useOrder";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import formatTime from "../lib/formatTime";
import { ORDER_STATUS, ORDER_STATUS_CONST } from "../lib/const";
import Totals from "../components/checkout/Totals";
import OrderKeys from "../components/orders/OrderKeys";
import { Button } from "@nextui-org/react";
import { IoMdAlert } from "react-icons/io";
function Order() {
  const { id } = useParams();
  const { order } = useOrder(id);
  const navigate = useNavigate();

  if (!order) return null;
  return (
    <main className="w-3/4 m-auto mt-8 flex flex-col md:flex-row gap-8 md:gap-40 min-h-screen">
      <AccountNav />
      <section className="md:w-3/4">
        <header>
          <Link to={"/ordenes"} className="flex gap-2 items-center mb-2">
            <IoMdArrowRoundBack className="text-gray-500 text-2xl" />
            <span>
              <p className="text-xs text-gray-500">Volver</p>
            </span>
          </Link>
          <h4 className="text-xl font-bold text-secondary">N° {order.id}</h4>
          <span className="text-xs text-gray-400">
            {formatTime(order.createdAt)} - {ORDER_STATUS[order.status].name}
            <Totals order={order} />
          </span>
        </header>
        {order.status === ORDER_STATUS_CONST.PAYED ? (
          <OrderKeys orderId={order.id} />
        ) : (
          OrderStatusMessage({ order, navigate })
        )}
      </section>
    </main>
  );
}

function OrderStatusMessage({ order, navigate }) {
  switch (parseInt(order.status)) {
    case ORDER_STATUS_CONST.WAITING_PAYMENT:
      return (
        <div className="flex flex-col gap-4 mt-4">
          <Button
            color="secondary"
            size="small"
            variant="solid"
            className="w-full"
            onPress={() => {
              navigate(`/checkout/${order.id}`);
            }}
          >
            Finalizar pago
          </Button>
        </div>
      );
    case ORDER_STATUS_CONST.WAITING_PAYMENT_APROVE:
      return (
        <div className="flex flex-col gap-4 items-center mt-8 bg-[#181818] p-4">
          <IoMdAlert className="text-center text-5xl text-warning " />
          <p className="text-center text-gray-400">
            Tu pago está siendo procesado, te avisaremos cuando se apruebe
          </p>
        </div>
      );
    case ORDER_STATUS_CONST.WAITING_PAYMENT_APROVE_AND_STOCK:
      return (
        <div className="flex flex-col gap-4 items-center mt-8 bg-[#181818] p-4">
          <IoMdAlert className="text-center text-5xl text-warning " />
          <p className="text-center text-gray-400">
            Tu pago está siendo procesado, te avisaremos cuando se apruebe
          </p>
        </div>
      );
    case ORDER_STATUS_CONST.WAITING_STOCK:
      return (
        <div className="flex flex-col gap-4 items-center mt-8 bg-[#181818] p-4">
          <IoMdAlert className="text-center text-5xl text-success " />
          <p className="text-center text-gray-400">
            Tu pago fue aprobado, estamos preparando tu pedido
          </p>
        </div>
      );

    default:
      return null;
  }
}

export default Order;
