import { useEffect, useState } from "react";
import { toast } from "sonner";
import api from "../lib/api";

const useProducts = ({ limit = 100 }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    try {
      const fetchProducts = async () => {
        const { data } = await api.get(`/chests?limit=${limit}`);
        setProducts(data);
      };
      fetchProducts();
    } catch (error) {
      toast.error("Error al obtener los productos");
    }
  }, [limit]);

  return products;
};

export default useProducts;
