import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  //Input,
} from "@nextui-org/react";
import { AcmeLogo } from "../AcmeLogo.jsx";
//import { Search } from "../icons/Search.jsx";
import NavUserMenu from "./NavUserMenu.jsx";
import { Link } from "react-router-dom";
import Cart from "../cart/CartModal.jsx";

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const menuItems = [
    { name: "Inicio", path: "/" },
    { name: "Productos", path: "/productos" },
    { name: "Sobre Nosotros", path: "/docs" },
   // { name: "Contacto", path: "/contact" },
  ]
  return (
    <Navbar id="nav" onMenuOpenChange={setIsMenuOpen}>
      <NavbarContent justify="start">
      <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand className="mr-4">
          <AcmeLogo />
          <p className="hidden sm:block font-bold text-inherit">Bio</p>
        </NavbarBrand>
        <NavbarContent className="hidden sm:flex gap-3">
          <NavbarItem>
            <Link color="foreground" to="/">
              Inicio
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link to="/productos" aria-current="page" color="secondary">
              Explorar
            </Link>
          </NavbarItem>
          {/* <NavbarItem>
            <Link color="foreground" to="/about">
              Sobre Nosotros
            </Link>
          </NavbarItem> */}

          <NavbarItem>
            <Link color="foreground" to="/docs">
              Sobre Nosotros
            </Link>
          </NavbarItem>
        </NavbarContent>
      </NavbarContent>

      <NavbarContent as="div" className="items-center" justify="end">
        {/* <Input
          className="hidden md:block"
          classNames={{
            base: "max-w-full sm:max-w-[10rem] h-10",
            mainWrapper: "h-full",
            input: "text-small",
            inputWrapper:
              "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20",
          }}
          placeholder="Buscar claves"
          size="sm"
          startContent={<Search size={18} />}
          type="search"
        /> */}
        <NavUserMenu />
        <Cart />
      </NavbarContent>
      <NavbarMenu>
        {menuItems.map(({name, path}, index) => (
          <NavbarMenuItem key={`${name}-${index}`}>
            <Link
              color={
                index === 2 ? "primary" : index === menuItems.length - 1 ? "danger" : "foreground"
              }
              className="w-full"
              to={path}
              size="lg"
            >
              {name}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
