import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Divider,
  Image,
  Button,
} from "@nextui-org/react";
import useDecryptKey from "../../hooks/useDecryptKey";

export default function KeyCard({ propKey }) {
  const { decriptedKey, decryptKey, encryptKey } = useDecryptKey(propKey.id);


  return (
    <Card className="max-w-[440px]">
      <CardHeader className="flex gap-3">
        <Image
          alt="nextui logo"
          height={40}
          radius="sm"
          src={process.env.REACT_APP_CDN_URL + "/" + propKey.imgId}
          width={40}
        />
        <div className="flex flex-col">
          <p className="text-md">{propKey.name}</p>
        </div>
      </CardHeader>
      <Divider />
      <CardBody>
        <p className="text-center">{decriptedKey || "*************"}</p>
        {!decriptedKey ? (
          <Button
            className="mt-2"
            color="secondary"
            size="small"
            variant="solid"
            onClick={decryptKey}
          >
            Ver clave
          </Button>
        ) : (
          <Button
            className="mt-2"
            color="secondary"
            size="small"
            variant="solid"
            onClick={encryptKey}
          >
            Ocultar clave
          </Button>
        )}
      </CardBody>
      <Divider />
    </Card>
  );
}
