import React from "react";
import useProducts from "../hooks/useProducts";
import ProductCard from "../components/products/ProductCard";

function Products() {
  const products = useProducts({ limit: 10 });

  return (
    <section className="w-3/4 m-auto my-8 min-h-screen">
        <header>
            <div className="flex justify-between">
                <div>
                <span className="text-secondary">Explorar</span>
                <h3 className="font-bold text-xl md:text-2xl">
                    Claves de software
                </h3>
                </div>
            </div>
        </header>
      <div className="flex flex-wrap justify-between gap-8 mt-8">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </section>
  );
}

export default Products;
