import About from "../components/docs/About";
import Purchase from "../components/docs/Purchase";

export default function Docs() {
  return (
    <main>
      <About />
      <Purchase />
    </main>
  );
}
