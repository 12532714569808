import React from "react";
import api from "../../lib/api";
import Totals from "./Totals";
import { Button } from "@nextui-org/react";
import { toast } from "sonner";

function Manual({ order }) {
  const [paymentData, setPaymentData] = React.useState({
    voucher: null,
  });

  const handleFile = (e) => {
    setPaymentData({
      ...paymentData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!paymentData.voucher) {
      toast.error("Debes subir un comprobante de pago");
      return;
    }
    try {
      await api.post(`/checkout/${order.id}/manual`, paymentData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.href = `/orden/${order.id}`;
    } catch (error) {
      toast.error(error.response.data.message)
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col mt-8 ">
      <label htmlFor="voucher" className="text-gray-500">
        Comprobante de pago
      </label>
      <input
        id="voucher"
        onInput={handleFile}
        type="file"
        name="voucher"
        className="file-input w-full max-w-xs bg-white text-gray-400 hover:outline-none focus:outline-none outline-none"
      />

      <Totals color={"text-blue-500"} order={order} />

      <Button type="submit" className="my-4 bg-blue-500"  size="small">
        Enviar comprobante
      </Button>

      <span className="text-danger text-xs">
        Un administrador revisará el pago y aprobará la orden a la brevedad
      </span>
    </form>
  );
}

export default Manual;
