import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import React, { useContext } from "react";
import { UserContext } from "../../context/User";
import { Link, useNavigate } from "react-router-dom";

function NavUserMenu() {
  const { user, logOut } = useContext(UserContext);
  const navigate = useNavigate();

  if (!user) {
    return (
      <Link to={"/login"} className="bg-secondary p-2 rounded-lg text-sm">
        Ingresar
      </Link>
    );
  }

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          isBordered
          as="button"
          className="transition-transform"
          color="secondary"
          name={user.name + " " + user.lastname}
          size="sm"
          src=""
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem key="profile" className="h-14 gap-2">
          <p className="font-semibold">Signed in as</p>
          <p className="font-semibold">{user.email}</p>
        </DropdownItem>
        <DropdownItem key="account" onPress={() => navigate("/cuenta")}>
          Mi cuenta
        </DropdownItem>
        <DropdownItem key="team_settings" onPress={() => navigate("/claves")}>
          Mis claves
        </DropdownItem>
        <DropdownItem key="analytics" onPress={() => navigate("/ordenes")}>
          Mis ordenes
        </DropdownItem>
        <DropdownItem onPress={() => logOut()} key="logout" color="danger">
          Salir
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default NavUserMenu;
