import React, { useContext } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CartContext } from "../../context/Cart";
import CartBody from "./CartBody";
import CartFooter from "./CartFooter";

export default function Cart() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { cart } = useContext(CartContext);

  if (!cart || !cart.requestedChests) return null;

  return (
    <>
      <Button onPress={onOpen}>
        <span className="text-xs font-semibold text-white bg-red-500 rounded-full h-4 w-4 absolute top-1 left-10">
          {cart.requestedChests.length}
        </span>
        <AiOutlineShoppingCart size={20} />
      </Button>
      <Modal
        placement="top"
        backdrop="blur"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Carrito</ModalHeader>
              <ModalBody>
                <CartBody closeModal={onClose}/>
              </ModalBody>
              <ModalFooter >
                <CartFooter closeModal={onClose}/>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
