import React, { useEffect } from "react";

import Manual from "../components/checkout/Manual";
import { IoCardOutline } from "react-icons/io5";
import { FaMoneyBill } from "react-icons/fa";
import { BsPaypal } from "react-icons/bs";
import { PAYMENT_METHOD } from "../lib/const";
import PayPal from "../components/checkout/PayPal";
import Stripe from "../components/checkout/Stripe";
import { useParams } from "react-router-dom";
import useOrder from "../hooks/useOrder";
import { IoMdArrowRoundBack } from "react-icons/io";
import DiscountCode from "../components/checkout/DiscountCode";

function Checkout() {
  const [paymentMethod, setPaymentMethod] = React.useState(
    PAYMENT_METHOD.STRIPE
  );
  const { id: orderId } = useParams();
  const { order, setOrder } = useOrder(orderId);

  useEffect(() => {
    document.getElementById("nav").classList.add("hidden");
    document.getElementById("footer").classList.add("hidden");
    document.querySelector("html").classList.add("bg-white");
    return () => {
      document.getElementById("nav").classList.remove("hidden");
      document.querySelector("body").classList.remove("bg-white");
      document.getElementById("footer").classList.remove("hidden");

    };
  }, []);
  if (!order) return null;

  return (
    <section className="w-2/4 m-auto mt-12 min-h-screen">
      <header className="flex items-center justify-between ">
        <div>
        <a href={"/cart/resume"} className="flex gap-2 items-center mb-2 text-blue-500">
            <IoMdArrowRoundBack className=" text-2xl" />
            <span>
              <p className="text-xs">Volver</p>
            </span>
          </a>
        <h1 className="text-3xl text-black font-bold">Checkout</h1>
        <h5 className=" text-xl text-black ">Seleccione un método de pago</h5>
        </div>
        <div className="flex gap-12 mt-8">
          <div
            onClick={() => setPaymentMethod(PAYMENT_METHOD.STRIPE)}
            className={`flex justify-center flex-col items-center cursor-pointer  ${
              paymentMethod === PAYMENT_METHOD.STRIPE ? "text-blue-600" : "text-gray-500"
            }`}
          >
            <IoCardOutline size={50} />
            <span className="text-xs">Tarjeta</span>
          </div>
          <div
            onClick={() => setPaymentMethod(PAYMENT_METHOD.PAYPAL)}
            className={`flex justify-center flex-col items-center cursor-pointer  ${
              paymentMethod === PAYMENT_METHOD.PAYPAL ? "text-blue-600" : "text-gray-500"
            }`}
          >
            <BsPaypal size={50} />
            <span className="text-xs">PayPal</span>
          </div>

          <div
            onClick={() => setPaymentMethod(PAYMENT_METHOD.MANUAL)}
            className={`flex justify-center flex-col items-center cursor-pointer  ${
              paymentMethod === PAYMENT_METHOD.MANUAL ? "text-blue-600" : "text-gray-500"
            }`}
          >
            <FaMoneyBill size={50} />
            <span className="text-xs">
              Transferencia - <br /> SINPE
            </span>
          </div>
        </div>
      </header>
      <DiscountCode order={order} setOrder={setOrder}/>
      {<PaymentForm paymentMethod={paymentMethod} order={order} />}
    </section>
  );
}

function PaymentForm({ paymentMethod, order }) {
  switch (paymentMethod) {
    case PAYMENT_METHOD.STRIPE:
      return <Stripe order={order} />;
    case PAYMENT_METHOD.PAYPAL:
      return <PayPal order={order} />;
    case PAYMENT_METHOD.MANUAL:
      return <Manual order={order} />;
    default:
      return null;
  }
}

export default Checkout;
