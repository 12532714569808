import { useContext, useState } from "react";
import api from "../lib/api";
import { UserContext } from "../context/User";
import { toast } from "sonner";

const useRegister = () => {
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [registerData, setRegisterData] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    lastname: "",
  });

  const handleChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!registerData.email || !registerData.password ||  !registerData.passwordConfirm || !registerData.name || !registerData.lastname) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    if (registerData.password.length < 8) {
      toast.error("La contraseña debe tener al menos 8 caracteres");
      return;
    }

    if (registerData.password !== registerData.passwordConfirm) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    try {
      setLoading(true);
      const res = await api.post("/user/register", registerData);
      setUser(res.data);
      window.location.href = "/";
      setLoading(false);
    } catch (error) {
      toast.error("Este email ya está registrado");
      setLoading(false);
    }
  };

  return {
    handleChange,
    handleSubmit,
    registerData,
    loading,
  };
};

export default useRegister;
