import { useEffect, useState } from "react";
import api from "../lib/api";

const useOrderKeys = (orderId) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const getKeys = async () => {
      const { data } = await api.get(`/orders/${orderId}/keys`);
      setKeys(data);
    };

    getKeys();
  }, [orderId]);

  return {keys};
};

export default useOrderKeys;
