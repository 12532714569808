import React from "react";
import { AcmeLogo } from "../AcmeLogo";
import Socials from "./Socials";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="footer" className="flex flex-col justify-start pb-4">
      <div className="w-3/4 m-auto grid grid-cols-2 space-y-12  md:grid-cols-4 space-x-11">
        <div>
          <AcmeLogo />
          <p className="text-xs text-gray-400 mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatum, quos, doloremque corrupti, quas voluptatibus quae
            voluptates doloribus fugit quia repellat. Quisquam voluptatum, quos,
            doloremque corrupti, quas voluptatibus quae voluptates doloribus
            fugit quia repellat.
          </p>

        </div>

        <div>
          <h6 className="font-bold">Links útiles</h6>
          <ul className="flex flex-col gap-3 mt-3">
            <li className="text-sm text-gray-400">
              <Link to={"/productos"}>Productos</Link>
            </li>

            <li className="text-sm text-gray-400">
              <Link to={"/docs#compra"}>¿Cómo comprar?</Link>
            </li>

            <li className="text-sm text-gray-400">
                <Link  to={"/docs#pago"}>¿Cómo pagar?</Link>
            </li>

            <li className="text-sm text-gray-400">
                <Link  to={"/términos"}>Términos y condiciones</Link>
            </li>
          </ul>
        </div>


        {/* <div>
          <h6 className="font-bold">Comunidad</h6>
          <ul className="flex flex-col gap-3 mt-3">

            <li className="text-sm text-gray-400">
              <Link to={"/sugerencias"}>Sugerencias</Link>
            </li>

            <li className="text-sm text-gray-400">
                <Link  to={"/chat"}>Chat</Link>
            </li>

            <li className="text-sm text-gray-400">
                <Link  to={"/blog"}>Blog</Link>
            </li>
          </ul>
        </div> */}

        <div className="hidden md:block">
        <h6 className="font-bold mb-4">Contáctanos</h6>
        {/* <Input placeholder="Correo electrónico" className="mt-3" endContent={
            <BsSendFill className="cursor-pointer text-secondary" />
        }/> */}
        <p className="text-gray-500 mb-4">support@biocdkeys.com</p>
        <Socials />
        </div>
      </div>
        <p className="text-center text-gray-400 mt-28">
          © 2023 Biocdkeys, Inc. All rights reserved.
        </p>
    </footer>
  );
}

export default Footer;
