import React from "react";

function Totals({ order, color  }) {
  return (
    <div className=" flex flex-col mt-8">
      <div className={`flex justify-between ${color && "text-gray-500"}`}>
        <strong className={color || "text-secondary"}> Subtotal:</strong>${" "}
        {order.subtotal.toFixed(2)}
      </div>

      <div className={`flex justify-between ${color && "text-gray-500"}`}>
        <strong className={color || "text-secondary"}> Descuento:</strong>- ${" "}
        {(order.subtotal * (order.discount / 100)).toFixed(2)}
      </div>

      <div className={`flex justify-between ${color && "text-gray-500"}`}>
        <strong className={color || "text-secondary"}> Total:</strong>${" "}
        {(order.subtotal - order.subtotal * (order.discount / 100)).toFixed(2)}
      </div>
    </div>
  );
}

export default Totals;
