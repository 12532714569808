import React, { useContext } from 'react'
import { UserContext } from '../../context/User';
import { Link } from "react-router-dom";


function AccountNav() {
    const {logOut } = useContext(UserContext);
  return (
    <nav>
        <ul className="flex md:flex-col  gap-3">
            <li>
                <Link to="/cuenta">Mi cuenta</Link>
            </li>
            <li>
                <Link to="/claves">Mis claves</Link>
            </li>
            <li>
                <Link to="/ordenes">Órdenes</Link>
            </li>
            <li onClick={() => logOut()}>
                Salir
            </li>
        </ul>
    </nav>
  )
}

export default AccountNav