import React, { useContext } from "react";
import { GiNightSleep } from "react-icons/gi";
import CartItem from "./CartItem";
import { CartContext } from "../../context/Cart";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";

function CartBody({closeModal}) {
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();
  if (!cart || !cart.requestedChests) return null;

  return (
    <div>
      {" "}
      {cart.requestedChests.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <GiNightSleep className="text-gray-400" size={60} />
          <p className="text-xs">
            Oops! Está muy tranquilo por aquí, ¿por qué no añades algo?
          </p>

          <Button
            onPress={() => {
              navigate("/productos");
             if (closeModal) closeModal();
            }}
            variant="bordered"
            className="mt-2 hover:border-secondary-500"
          >
            Explorar
          </Button>
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          {cart.requestedChests.map((chest) => (
            <CartItem
              key={chest.id}
              chestId={chest.id}
              quantity={chest.quantity}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CartBody;
