import React from 'react'
import KeyCard from './KeyCard'

function KeysContainer({keys}) {
  return (
    <div className="mt-8 grid md:grid-cols-3 gap-3">
    {keys.map((key) => (
      <KeyCard key={key.id} propKey={key} />
    ))}
  </div>
  )
}

export default KeysContainer