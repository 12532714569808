import { useEffect, useState } from "react";
import api from "../lib/api";

const useMyKeys = () => {
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await api.get("/keys");
        setKeys(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchKeys();
  }, []);

  return { keys, loading };
};

export default useMyKeys;
