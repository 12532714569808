import { loadStripe } from "@stripe/stripe-js";
import React, { useContext } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "sonner";
import Totals from "./Totals";
import { Button } from "@nextui-org/react";
import api from "../../lib/api";
import { UserContext } from "../../context/User";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Stripe({ order }) {
  const options = {
    mode: "payment",
    currency: "usd",
    amount:
      parseInt(
        (order.subtotal - order.subtotal * (order.discount / 100)).toFixed(2)
      ) * 100,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeForm order={order} />
    </Elements>
  );
}

function StripeForm({ order }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      // obtener tarjeta de PaymentElement
      card: elements.getElement(CardElement),
    });

    if (error) {
      toast.error(error.message);
      return;
    }

    try {
      toast.loading("Procesando pago...");
      const { data } = await api.post(`/checkout/${order.id}/stripe`, {
        token: paymentMethod.id,
      });
      if (!data.success) {
        toast.error(data.message);
        return;
      }

      window.location.href = `/orden/${order.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (!order || !user) return null;

  return (
    <div>
      <div className=" mt-4 ">
        <div className="flex flex-col">
          <label htmlFor="user" className="text-gray-500 text-sm">
            Titular
          </label>
          <input
            id="user"
            type="text"
            className="bg-white text-gray-400 hover:outline-none focus:outline-none outline-none"
            defaultValue={user.name + " " + user.lastName}
          />
        </div>

        <div className="flex flex-col mt-4">
          <label htmlFor="correo" className="text-gray-500 text-sm">
            Correo electrónico
          </label>
          <input
            id="correo"
            type="text"
            className="bg-white text-gray-400 hover:outline-none focus:outline-none outline-none"
            defaultValue={user.email}
          />
        </div>

        <CardElement
        className="mt-4"
          options={{
            iconStyle: "solid",
            style: {
              base: {
                lineHeight: "50px",
                fontSize: "16px",
                color: "gray",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />

       
      </div>
      <Totals color={"text-blue-500"} order={order} />
      <Button onClick={handleSubmit} className="my-4 w-full bg-blue-500" >
        Pagar
      </Button>
    </div>
  );
}

export default Stripe;
