import { createContext, useCallback, useContext, useEffect, useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";
import { UserContext } from "./User";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const {user } = useContext(UserContext);
  const [cart, setCart] = useState(null);
  const [stock, setStock] = useState(false);

  // fetch cart from backend
  useEffect(() => {
    const fetchCart = async () => {
      try {
        const { data } = await api.get("/orders/current");
        setCart(data);
      } catch (error) {
        setCart({});
      }
    };
    fetchCart();
  }, []);

  // Add item to cart
  const addToCart = async (newChest) => {
    // if key already exist, increase quantity
    if (!user) {
      window.location.href = "/login";
      return;
    }
    const newChestsArray = [...cart.requestedChests];
    const index = newChestsArray.findIndex((key) => key.id === newChest);
    if (index > -1) {
      newChestsArray[index].quantity++;
    } else {
      newChestsArray.push({
        id: newChest,
        quantity: 1,
      });
    }
    updateRequestedChests(newChestsArray);
  };

  const checkSotck = useCallback(async () => {
    if (cart) {
      const { data } = await api.get(`/orders/${cart.id}/stock`);
      setStock(data.stock);
    }
  }, [cart]);


  const updateRequestedChests = useCallback(
    async (newChestsArray) => {
      try {
        const { data } = await api.put("/orders/requestedChests", {
          requestedChests: newChestsArray,
        });
        setCart(data.order);
        checkSotck();
        toast.success("Carrito actualizado");
      } catch (error) {
        toast.error("Error al agregar el producto al carrito");
      }
    },
    [checkSotck]
  );

  // Remove item from cart
  const removeFromCart = (chest) => {
    const newChestsArray = cart.requestedChests.filter(
      (item) => item.id !== chest
    );
    setCart({ ...cart, requestedChests: newChestsArray });
    updateRequestedChests(newChestsArray);
  };

  const handleQuantity = (chest, quantity) => {
    // verify if quantity is a number
    if (!quantity.match(/^[0-9]+$/)) {
      toast.error("La cantidad debe ser un número, carrito no actualizado");
      setCart(cart);
      return;
    }
    if (parseInt(quantity) === 0) {
      removeFromCart(chest);
    } else {
      const newChestsArray = [...cart.requestedChests];
      const index = newChestsArray.findIndex((key) => key.id === chest);
      newChestsArray[index].quantity = quantity;
      setCart({ ...cart, requestedChests: newChestsArray });
      updateRequestedChests(newChestsArray);
    }
  };

  const placeOrder = async () => {
    try {
      const { data } = await api.put("/orders/place?waitStock=true");
      const pulling = setInterval(async () => {
        const { data: result } = await api.get(
          `/orders/queue/${data.job}`
        );

        if (result.state === "completed") {
          clearInterval(pulling);
          window.location.href = `/checkout/${cart.id}`;
        } else if (result.state === "failed") {
          clearInterval(pulling);
          toast.error(result.message);
        }
      }, 1000);
    } catch (error) {
      toast.error("Error al realizar la compra");
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        handleQuantity,
        placeOrder,
        stock,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
