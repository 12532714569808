import { useEffect, useState } from "react";
import api from "../lib/api";

const useOrders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const { data } = await api.get("/orders");
      setOrders(data);
    };
    fetchOrders();
  }, []);

  return {orders};
};

export default useOrders;
