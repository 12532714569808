import { useEffect, useState } from "react";
import api from "../lib/api";

const useChest = (id) => {
  const [chest, setChest] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChest = async () => {
      const {data} = await api.get(`/chests/${id}`);
      setChest(data.chest);
      setLoading(false);
    };
    fetchChest();
  }, [id]);

  return { chest, loading };
};


export default useChest;