import React from 'react'
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";

function Socials() {
  return (
    <div className='flex gap-3'>
            <a href="https://facebook.com/">
              <BsFacebook/>
            </a>
            <a href="https://instagram.com/">
              <BsInstagram />
            </a>
            <a href="https://instagram.com/">
              <BsWhatsapp />
            </a>
          </div>
  )
}

export default Socials