import React from "react";
import api from "../../lib/api";
import { toast } from "sonner";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Totals from "./Totals";

function PayPal({ order }) {
  async function createOrder() {
    const { data } = await api.post(`/checkout/${order.id}/paypal`);
    console.log(data);
    return data.id;
  }

  async function onApprove(data) {
    try {
      await api.post(`/checkout/${order.id}/paypal/capture`, {
        token: data.orderID,
      });
      toast.loading("Procesando pago");

      setTimeout(() => {
        toast.success("Pago realizado con éxito");
        window.location.href = `/orden/${order.id}`;
      }, 3000);
    } catch (error) {
      toast.error("Error al procesar el pago");
    }
  }

  function onError(err) {
    console.log(err);
    toast.error("La orden no se pudo crear");
  }

  function onCancel() {
    toast.warning("Proceso de pago cancelado");
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}
    >
      <div className="mt-16 ">
      <Totals color={"text-blue-500"} order={order} />

        <div className="bg-white p-12 rounded-xl justify-center flex">
          <PayPalButtons
            onCancel={onCancel}
            onError={onError}
            createOrder={createOrder}
            onApprove={onApprove}
            className="w-1/2 "
            style={{
              layout: "vertical",
              color: "blue",
              shape: "pill",
              backgroundColor: "#000",
            }}
          />
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default PayPal;
