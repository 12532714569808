import { useEffect, useState } from "react";
import api from "../lib/api";

const useOrder = (id) => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const { data } = await api.get(`/orders/${id}`);
        setOrder(data);
        setLoading(false);
      } catch (error) {
        setOrder({});
      }
    };
    fetchOrder();
  }, [id]);

  return { order, loading, setOrder };
};

export default useOrder;
