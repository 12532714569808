import React from "react";
import AccountNav from "../components/Account/AccountNav";
import useOrders from "../hooks/useOrders";
import OrderCard from "../components/orders/OrderCard";

function Orders() {
  const { orders } = useOrders();

  return (
    <main className="md:w-3/4 px-4 md:px-0 m-auto mt-8 flex flex-col md:flex-row gap-8 md:gap-40 min-h-screen">
      <AccountNav />
      <section className="md:w-3/4">
        <header>
          <h4 className="text-3xl font-bold">Mis Órdenes</h4>
          <p className="text-gray-400 text-xs">
            Las órdenes que has realizado apareceran aqui.
          </p>
        </header>
        <div className="flex flex-wrap gap-4 mt-8">
          {orders.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
        </div>
      </section>
    </main>
  );
}

export default Orders;
