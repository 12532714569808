import React from "react";
import { AcmeLogo } from "../components/AcmeLogo";
import useRecoveryMail from "../hooks/useRecoveryMail";

function RecoveryMail() {
  const { handleChange, handleSubmit, loading, sendemail, recoveryData } =
    useRecoveryMail();
  return (
    <>
      {sendemail ? (
        <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8 md:w-1/2 m-auto">
          <p class="mt-10 text-center text-sm text-gray-500">
            Si este correo se encuentra registrado en nuestro sistema, se ha
            enviado un correo a tu cuenta ({recoveryData.email}) con las
            instrucciones para recuperar tu contraseña, si no lo encuentras
            revisa tu bandeja de spam.
            <button
              onClick={(e) => handleSubmit(e)}
              type="button"
              class="flex w-full  mt-8 justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              <span className={loading && "loading loading-spinner"}></span>
              {loading ? "Cargando" : "Volvel a enviar correo de recuperación"}
            </button>
          </p>
        </div>
      ) : (
        <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
          <div class="sm:mx-auto flex justify-center sm:w-full sm:max-w-sm">
            <AcmeLogo />
          </div>

          <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" action="#" method="POST">
              <div>
                <label for="email" class="block text-sm font-medium leading-6 ">
                  Email address
                </label>
                <div class="mt-2">
                  <input
                    onChange={(e) => handleChange(e)}
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    placeholder="Email"
                    class="block w-full rounded-md border-0 py-1.5 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div></div>

              <div>
                <button
                  onClick={(e) => handleSubmit(e)}
                  type="button"
                  class="flex w-full justify-center rounded-md bg-secondary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  <span className={loading && "loading loading-spinner"}></span>
                  {loading ? "Cargando" : "Enviar correo de recuperación"}
                </button>
              </div>
            </form>

            <p class="mt-10 text-center text-sm text-gray-500">
              ¿Problemas?{" "}
              <a
                href="mailto:support@biocdkeys.com"
                class="font-semibold leading-6 text-secondary"
              >
                support@biocdkeys.com
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default RecoveryMail;
