// Importa las dependencias necesarias
import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import { UserContext } from "../context/User";
import RecivedMessage from "../components/chat/ReceivedMessage";
import SentMessage from "../components/chat/SentMessage";
import { Button, Input } from "@nextui-org/react";
import { IoSend } from "react-icons/io5";
import useChat from "../hooks/useChat";

const Chat = () => {
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const { user } = useContext(UserContext);
  const { chat, messages, setMessages } = useChat();

  useEffect(() => {
    // Conecta con el servidor Socket.io
    const newSocket = io(process.env.REACT_APP_CHAT_SOCKET_URL, {
      withCredentials: true,
    }); // Reemplaza con la URL de tu servidor

    // Configura los listeners para los eventos de Socket.io
    newSocket.on("connect", () => {
      console.log("Conectado al servidor de Socket.io");
    });

    newSocket.on("disconnect", () => {
      console.log("Desconectado del servidor de Socket.io");
    });

    newSocket.on("message", (message) => {
      // Actualiza el estado de los mensajes cuando se recibe un nuevo mensaje
      setMessages((prevMessages) => [...prevMessages, message]);

      setTimeout(() => {
        document.getElementById("messages-render").scrollTo(0, document.getElementById("messages-render").scrollHeight);
      } , 100);

    });

    // Almacena el objeto socket en el estado
    setSocket(newSocket);

    // Cierra la conexión cuando el componente se desmonta
    return () => {
      newSocket.disconnect();
    };
  }, [setMessages]); // El array vacío asegura que este efecto solo se ejecute una vez al montar el componente

  const sendMessage = () => {
    if (!newMessage) return;
    // Envía un mensaje al servidor a través de Socket.io
    socket.emit("message", { newMessage, chatId: chat.id, to: chat.admin });
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: newMessage, user: user.id, date: new Date() },
    ]);

    // Limpia el campo de nuevo mensaje
    setNewMessage("");
  };

  if (!chat || !user) return null;

  return (
    <section className="w-3/4 m-auto">
      <div>
        <h1>Chat</h1>
        <span>{chat.id}</span>
      </div>

      <div id="messages-render" className="h-[80vh] overflow-y-scroll flex flex-col gap-3 ">
        {messages.map((message, index) => {
          if (message.user === user.id) {
            return (
              <SentMessage
                key={index}
                message={message.message}
                date={message.createdAt}
              />
            );
          } else {
            return (
              <RecivedMessage
                key={index}
                message={message.message}
                date={message.createdAt}
              />
            );
          }
        })}
      </div>

      <div className="flex gap-2 mt-4">
        <Input
          placeholder="Mensaje"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <Button color="secondary" onClick={() =>{
          sendMessage();
          setTimeout(() => {
            document.getElementById("messages-render").scrollTo(0, document.getElementById("messages-render").scrollHeight);
          } , 100);
        }}>
          <IoSend />
        </Button>
      </div>
    </section>
  );
};

export default Chat;
