import React, { useContext } from "react";
import CartBody from "../components/cart/CartBody";
import { CartContext } from "../context/Cart";
import { Button } from "@nextui-org/react";

function Cart() {
  const { cart, placeOrder, stock } = useContext(CartContext);

  if (!cart || !cart.requestedChests) return null;
  return (
    <section className="min-h-screen">
      <div className="w-1/4 m-auto mt-12">
        <div className="">
          <h3 className="text-xl text-gray-400 font-bold mb-4">Resumen</h3>
          <CartBody />
        </div>

        {cart.requestedChests.length > 0 && (
          <div className=" flex flex-col mt-8 ">
            <div className="flex justify-between">
              <strong className="text-success">Items:</strong>
              {cart.requestedChests.length}
            </div>
            <div className="flex justify-between">
              <strong className="text-success">Subtotal:</strong>${" "}
              {cart.subtotal.toFixed(2)}
            </div>

            {!stock && (
              <div className="flex justify-between flex-col mt-4">
                <span className="text-xs text-danger">
                  En este momento no tenemos stock suficiente para completar tu pedido, si decides continuar pondremos tu pedido en lista de espera y te asignaremos las claves en cuanto tengamos stock disponible (aproximadamente 1 - 2 horas)
                </span>
              </div>
            )}

            <Button
              className="mt-4"
              color="secondary"
              size="large"
              variant="flat"
              onPress={() => placeOrder()}
            >
             {!stock  ? "Realizar pedido y esperar" : "Realizar pedido"}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
}

export default Cart;
