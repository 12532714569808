import React, { useContext } from "react";
import useChest from "../../hooks/useChest";
import { Button, Input } from "@nextui-org/react";
import { CartContext } from "../../context/Cart";
import { FiTrash } from "react-icons/fi";

function CartItem({ chestId, quantity }) {
  const { chest } = useChest(chestId);
  const { handleQuantity, removeFromCart } = useContext(CartContext);
  if (!chest) return null;
  return (
    <div className="flex items-center justify-between">
      <img
        className="h-20 rounded-2xl"
        src={process.env.REACT_APP_CDN_URL + "/" + chest.imgId}
        alt=""
      />

      <div className="w-1/2">
        <p className="font-bold">{chest.name}</p>
        <Input
          onInput={(e) => handleQuantity(chestId, e.target.value)}
          label="Cantidad"
          type="number"
          accept="number"
          defaultValue={parseInt(quantity)}
        />
      </div>
      <Button variant="ghost" className="border-none hover:text-red-500 flex justify-end" onPress={() => removeFromCart(chestId)}>
        <FiTrash className="" size={20} />
      </Button>
    </div>
  );
}

export default CartItem;
