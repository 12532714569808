import React from "react";
import KeysContainer from "../keys/KeysContainer";
import useOrderKeys from "../../hooks/useOrderKeys";

function OrderKeys({ orderId }) {
  const { keys } = useOrderKeys(orderId);

  if (!keys) return null;
  return <KeysContainer keys={keys} />;
}

export default OrderKeys;
