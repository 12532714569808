import { useEffect, useState } from "react";
import api from "../lib/api";

const useChat = () => {
  const [chat, setChat] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const { data } = await api.get("/chat");
        setChat(data.chat);
      } catch (error) {
        console.log(error);
      }
    };
    fetchChat();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await api.get("/chat/messages");
        setMessages(data.messages);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat) {
      fetchMessages();
    }
  } , [chat]);

  return { chat, messages, setMessages };
};


export default useChat;