import React from "react";
import { BsInbox } from "react-icons/bs";
import { PiSquaresFour } from "react-icons/pi";
import { AiOutlineShopping } from "react-icons/ai";
import { SlWallet } from "react-icons/sl";

function Purchase() {
  return (
    <div className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-secondary">
                ¿Cómo comprar?
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                Adquiere una licencia
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-400">
                Adquirir una licencia en Biocdkeys es muy sencillo, solo debes
                seguir los siguientes pasos:
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/img/docs/keys.png"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <ul>
            <li className="mb-8">
              <div className="w-80">
                <PiSquaresFour className="text-secondary text-6xl" />
                <h3 className="text-xl">Crea una cuenta</h3>
                <p className="text-gray-400 font-light">
                  Registra tus datos personales en menos de un minuto, esto nos
                  ayudará a brindarte un mejor servicio en la compra de tus
                  licencias.
                </p>
              </div>
            </li>

            <li className="mb-8">
              <div className="w-80">
                <AiOutlineShopping className="text-secondary text-6xl" />
                <h3 className="text-xl">Elige tus claves</h3>
                <p className="text-gray-400 font-light">
                  Elige las claves que desees y agrégalas al carrito. Si no hay
                  stock, te las enviaremos en cuanto estén disponibles.
                </p>
              </div>
            </li>

            <li className="mb-8">
              <div className="w-80">
                <SlWallet className="text-secondary text-6xl" />
                <h3 className="text-xl">Confirmar y pagar</h3>
                <p className="text-gray-400 font-light">
                  Podrás realizar el pago mediante PayPal, tarjeta de crédito o
                  transferencia/SINPE. Pagos 100% seguros.
                </p>
              </div>
            </li>

            <li className="mb-8">
              <div className="w-80">
                <BsInbox className="text-secondary text-6xl" />
                <h3 className="text-xl">Listo</h3>
                <p className="text-gray-400 font-light">
                  El sistema confirmará tu pago y te enviará un correo con las
                  claves de tu compra en menos de 5 minutos. También podrás ver
                  tus claves en tu perfil en la sección de "Mis claves".
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
