import { useState } from "react";
import api from "../lib/api";
import { toast } from "sonner";

const useRecoveryMail = () => {
  const [loading, setLoading] = useState(false);
  const [recoveryData, setLogin] = useState({
    email: "",
  });
  const [sendemail, setSendEmail] = useState(false);

  const handleChange = (e) => {
    setLogin({
      ...recoveryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (recoveryData.email === "") {
      toast.error("Ingresa tu correo");
      return;
    }
    try {
      setLoading(true);
      await api.post("/user/recovery", recoveryData);
      setSendEmail(true);
      setLoading(false);
    } catch (error) {
      toast.error("Hubo un error al enviar el correo de recuperación");
      setLoading(false);
    }
  };

  return {
    handleChange,
    handleSubmit,
    recoveryData,
    loading,
    sendemail,
  };
};

export default useRecoveryMail;
