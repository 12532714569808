import React from "react";

function About() {
  return (
    <div className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-secondary">
                Sobre nosotros
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                ¿Quienes somos?
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-400">
                Biocdkeys es una tienda digital que se dedica a ofrecer software
                con entregas automatizadas.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/img/docs/bio.png"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-400 lg:max-w-lg">
              <p>
                Nuestro objetivo es hacerte la vida más fácil al proporcionarte
                códigos de licencia digital en cuestión de minutos, para que
                puedas activar tus sistemas operativos y suites de office de
                manera completamente original y permanente.
                <br /> <br />
                Nuestra amplia variedad de software incluye las últimas
                versiones de sistemas operativos y suites de office líderes en
                el mercado, para que puedas aumentar la productividad de tu
                equipo sin tener que preocuparte por la autenticidad del
                software.
                <br /> <br />
                En Biocdkeys, nos enorgullecemos de nuestra excelente atención
                al cliente, lo que significa que siempre estamos disponibles
                para ayudarte con cualquier pregunta o inquietud que puedas
                tener. Así que, si necesitas mejorar la eficiencia de tu
                empresa, no dudes en visitar nuestra tienda digital y descubrir
                la solución perfecta para tus necesidades. ¡Te esperamos!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
