import React, { useContext } from "react";
import { CartContext } from "../../context/Cart";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";

function CartFooter({closeModal}) {
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();

  if (!cart || !cart.requestedChests || cart.requestedChests.length === 0)
    return null;

  return (
    <div className="flex justify-between items-center w-full">
      <div>
        <p>
          <span className="font-semibold">Subtotal:</span>{" "}
          {cart.subtotal.toFixed(2)} USD
        </p>
      </div>
      <Button color="primary" onPress={() => {navigate("/cart/resume"); closeModal()}}>
        Confirmar y pagar
      </Button>
    </div>
  );
}

export default CartFooter;
