import { Button, Image } from "@nextui-org/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRightShort, BsInbox } from "react-icons/bs";
import { PiSquaresFour } from "react-icons/pi";
import { AiOutlineShopping } from "react-icons/ai";
import { SlWallet } from "react-icons/sl";
import ProducCarousel from "../components/products/ProducCarousel";

function Home() {
  const navigate = useNavigate();
  return (
    <main>
      <section className=" h-[80vh] px-8 flex flex-col md:flex-row gap-8 md:gap-0 items-center justify-center md:justify-around">
        <div>
          <h2 className="text-secondary font-light">Biocdkeys</h2>
          <h1 className="font-bold text-3xl md:text-5xl ">
            Tus claves de software <br /> rápido y fácil
          </h1>
          <p className="text-gray-500 mt-3">
            Claves de software seguras y con entrega inmediata
          </p>
          <Button
            variant="bordered"
            className="mt-2 hover:border-secondary-500"
            onPress={() => navigate("/productos")}
          >
            Explorar
          </Button>
        </div>

        <div>
          <Image src="/img/cover.png" className="w-[300px] md:w-[500px]" />
        </div>
      </section>

      <section className="flex flex-col items-center md:min-h-screen mb-8">
        <header className="flex justify-between w-3/4">
          <div>
            <span className="text-secondary">Destacadas</span>
            <h3 className="font-bold text-xl md:text-2xl">
              Claves de software
            </h3>
          </div>
          <div className="flex gap-2 items-center">
            <Link to="/productos">Ver todo</Link>
            <BsArrowRightShort size={22} className="font-bold" />
          </div>
        </header>

        <ProducCarousel />
      </section>

      <section className="flex flex-col items-center mb-72 mt-72 md:mt-0">
        <header className="flex justify-between w-3/4">
          <div>
            <span className="text-secondary">¿Cómo comprar?</span>
            <h3 className="font-bold text-xl md:text-2xl">
              Adquiere una licencia
            </h3>
          </div>
        </header>

        <div className="flex flex-wrap gap-8 md:gap-8 justify-between w-3/4 mt-8">
          <div className="w-80">
            <PiSquaresFour className="text-secondary text-6xl" />
            <h3 className="text-xl">Crea una cuenta</h3>
            <p className="text-gray-400 font-light">
              Registra tus datos personales en menos de un minuto, esto nos
              ayudará a brindarte un mejor servicio en la compra de tus
              licencias.
            </p>
          </div>

          <div className="w-80">
            <AiOutlineShopping className="text-secondary text-6xl" />
            <h3 className="text-xl">Elige tus claves</h3>
            <p className="text-gray-400 font-light">
              Elige las claves que desees y agrégalas al carrito. Si no hay
              stock, te las enviaremos en cuanto estén disponibles.
            </p>
          </div>

          <div className="w-80">
            <SlWallet className="text-secondary text-6xl" />
            <h3 className="text-xl">Confirmar y pagar</h3>
            <p className="text-gray-400 font-light">
              Podrás realizar el pago mediante PayPal, tarjeta de crédito o
              transferencia/SINPE. Pagos 100% seguros.
            </p>
          </div>

          <div className="w-80">
            <BsInbox className="text-secondary text-6xl" />
            <h3 className="text-xl">Listo</h3>
            <p className="text-gray-400 font-light">
              El sistema confirmará tu pago y te enviará un correo con las
              claves de tu compra en menos de 5 minutos. También podrás ver tus
              claves en tu perfil en la sección de "Mis claves".
            </p>
          </div>
        </div>
      </section>

    </main>
  );
}

export default Home;
